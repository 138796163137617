<template>
  <div class="md:my-20">
    <h1 class="mb-4 text-xl font-semibold text-gray-800">Login</h1>
    <p
      class="text-red-500 text-xs my-2 font-semibold"
      v-if="error.state === true"
    >
      *{{ error.message }}
    </p>
    <p class="text-xs text-green-500 my-2 font-semibold" v-if="message != ''">
      {{ message }}
    </p>
    <form @submit.prevent="login">
      <div class="mt-4">
        <label class="leading-relaxed" for="email">Email</label>
        <t-input
          id="email"
          name="email"
          type="text"
          v-model="email"
          placeholder="Email"
          required
        ></t-input>
      </div>
      <div class="mt-4">
        <label class="leading-relaxed" for="password">Password</label>
        <t-input
          id="password"
          v-model="password"
          name="password"
          type="password"
          placeholder="************"
          required
        ></t-input>
      </div>
      <div class="mt-4">
        <t-button
          type="submit"
          class="w-full flex items-center justify-center space-x-4"
        >
          <span>Log in</span> <spinner v-if="loading"></spinner
        ></t-button>
      </div>
      <hr class="my-8" />
      <p class="mt-4">
        <router-link
          class="text-sm font-medium text-amber-500 hover:underline"
          :to="{ name: 'ForgotPassword', query: { email: email } }"
        >
          Forgot your password?
        </router-link>
      </p>
    </form>
  </div>
</template>

<script>
import authApi from '../../api/auth'
import Spinner from '../../components/Utils/Spinner'

export default {
  name: 'Login',
  components: {
    Spinner
  },
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      message: '',
      error: {
        state: false,
        message: ''
      }
    }
  },
  methods: {
    async login() {
      this.loading = true
      this.error.state = false

      if (this.email === '' || this.password === '') {
        this.error.state = true
        this.error.message = 'Please input your email and password'
        this.loading = false
        return
      }

      const res = await authApi
        .auth()
        .login({ email: this.email, password: this.password })
      if (res.error) {
        console.log(res)
        this.error.state = true
        this.error.message = res.errorMessage
        this.loading = false
        return
      }
      this.$store.commit('updateUserData', res.data.user)
      this.$store.commit('setToken', res.data.token)

      this.message = res.message
      this.loading = false

      if (
        res.data.user.role === 'superadmin' ||
        res.data.user.role === 'admin'
      ) {
        this.$router.push({ name: 'Dashboard' })
        this.$store.commit('alert', {
          error: false,
          message: 'Login successful',
          status: true
        })
        return
      }

      this.$store.commit('alert', {
        error: false,
        message: 'Login successful',
        status: true
      })
      this.$router.push({ name: 'Workspace' })
    }
  }
}
</script>

<style></style>
